import React from "react"

export const withError = Component => ({ name = "Error", page, ...props }) => {
  const paths = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "404",
    },
  ]
  Component.displayName = name
  return (
    <Component
      {...props}
      products={page?.products}
      buttonLink={page?.buttonLink?.link}
      buttonText={page?.buttonLink?.title}
      title={page?.title}
      paths={paths}
    />
  )
}
