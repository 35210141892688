import React from "react"
import { graphql } from "gatsby"

import { Error as Page } from "../components/Error/Error"

export const query = graphql`
  query {
    page: sanityPageError {
      title
      buttonLink {
        title
        link
      }
      products {
        title
        sections {
          products {
            shopify {
              shopifyId
              shopifyHandle
            }
          }
        }
      }
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
