import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { navigate } from "gatsby"

import { withError } from "./withError"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import { StyledH1 } from "../Styled/Text"
import { StyledButton } from "../Styled/Button"
import FeaturedProductCarousel from "../Section/FeaturedProductCarousel/FeaturedProductCarousel"
import Breadcrumb from "../Breadcrumb/Breadcrumb"

const Container = styled(StyledContainer)`
  ${tw`pt-11-2 md:pt-16 pb-6-4 md:pb-10-4 flex items-center flex-col relative`}
`

const Title = styled(StyledH1)`
  ${tw`mb-1-6 md:mb-3-2 text-center`}
`

const Button = styled(StyledButton)`
  ${tw`w-full max-w-23-2 sm:max-w-32`}
`

export const Error = withError(({ title, buttonText, buttonLink, products, paths }) => (
  <>
    <BackgroundContainer background={`light`}>
      <Container>
        {paths && <Breadcrumb paths={paths} />}
        <Title>{title}</Title>
        {buttonText && buttonLink ? (
          <Button onClick={() => navigate(buttonLink)} height={`56`} textSize={`56`} colour={`light`} border>
            {buttonText}
          </Button>
        ) : null}
      </Container>
    </BackgroundContainer>
    <FeaturedProductCarousel {...products} />
  </>
))

Error.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
}

export default Error
